<script setup>
  import { handleError } from '@/utils/error'

  const { mobility: mob } = useCareerSite()
  const { href } = useRequestURL()
  const { params, query } = useRoute()
  const { findOne } = useStrapi4()
  const client = useStrapiClient()

  const firstLoad = ref(true)
  const mobility = mob.value || 'mobility' in query

  const careerSite = reactive({
    ...(await useAsyncData(`career-${params.slug}`, async () => {
      const res = await findOne('career-sites', params.slug, {
        fields: '*',
        populate: {
          cover: { fields: ['url'] },
          employer: {
            fields: ['name', 'slug', 'website', 'acronym'],
            populate: {
              cover: { fields: ['url'] },
              logo: { fields: ['url'] },
              page: {
                fields: ['id'],
                populate: {
                  photos: {
                    fields: ['id'],
                    populate: {
                      file: { fields: ['url'] }
                    }
                  }
                }
              },
              seo: {
                fields: ['metaDescription', 'metaTitle', 'structuredData'],
                populate: { metaImage: { fields: ['url'] } }
              }
            }
          },
          photo1: { fields: ['url'] },
          photo2: { fields: ['url'] },
          photo3: { fields: ['url'] },
          photo4: { fields: ['url'] }
        }
      })
      firstLoad.value = false
      return res.data
    }))
  })

  const careerData = computed(() => careerSite.data ?? {})

  if (careerData.value) {
    const data = careerData.value
    const seo = data.employer?.seo ?? {}
    const metaTitle = `${data.employer?.name} - ${mobility ? 'Mobilité interne' : 'Page emploi'}`
    useSeo({
      metaTitle,
      metaDescription: data.introText,
      metaRobots: seo.metaRobots,
      structuredData: seo.structuredData
    })

    useHead({
      bodyAttrs: {
        class: 'font-sans text-base'
      },
      htmlAttrs: {
        class: 'md:text-[18px]'
      },
      titleTemplate: metaTitle,
      meta: [{ hid: 'og:image', property: 'og:image', content: useStrapiMedia(data.cover?.url ?? data.employer?.cover?.url) }]
    })

    if (careerData.value.employer) {
      useFavicon(useStrapiMedia(careerData.value.employer.logo.url) || '/images/favicon.jpg')
    }
  }

  if (process.client && !('preview' in query)) {
    client(`/career-sites/${careerData.value.id}/view/${mobility ? 1 : 0}`, {
      body: { url: href },
      method: 'POST'
    }).catch(error => handleError(error))
  }

  definePageMeta({ layout: false })

  onMounted(() => {
    if ('preview' in query) setInterval(careerSite.refresh, 10000)
  })
</script>

<template>
  <Loading class="mx-auto max-w-screen-xl px-4 py-16" v-if="firstLoad && careerSite.pending" :color="careerData.primaryColor" />

  <Error class="mx-auto max-w-screen-xl px-4 py-16" v-else-if="careerSite.error" :retry="careerSite.refresh" />

  <div v-else :style="{ color: careerData.textColor }">
    <PagesCareerHeader :career-site="careerData" :mobility="mobility" subHeader />

    <PagesCareerIndexJobs :career-site="careerData" :mobility="mobility" />

    <PagesCareerIndexFigures :career-site="careerData" />

    <PagesCareerIndexPhotos :career-site="careerData" />

    <PagesCareerFooter :color="careerData.primaryColor" :employer="careerData.employer" />
  </div>

  <Notifications />
</template>
